import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div className="Home fadein">
                <main>
                    <h1 className="fadein-1">Hi.</h1>
                    <h1 className="fadein-2">My name is Helen.</h1>
                    <h1 className="fadein-3">I'm a software engineer.</h1>
                </main>
            </div>
        )
    }
}

export default Home;